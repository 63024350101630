<template>
  <div>
    <div class="contentALL">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="auto"
        class="demo-ruleForm"
      >
        <div class="flex">
          <el-form-item
            label="企业类型"
            prop="type"
            :class="
              (ruleForm.classes != 5 &&
                ruleForm.classes != '' &&
                ruleForm.type == 1) ||
              (ruleForm.classes == 10 &&
                ruleForm.classes != '' &&
                ruleForm.type == 4) ||
              (ruleForm.classes == 9 &&
                ruleForm.classes != '' &&
                ruleForm.type == 3) ||
              (ruleForm.classes == 6 &&
                ruleForm.classes != '' &&
                ruleForm.type == 2)
                ? ''
                : 'forTiem-L'
            "
          >
            <el-select
              v-model="ruleForm.type"
              @change="regionChange"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="企业类别"
            prop="classes"
            :class="
              (ruleForm.classes != 5 &&
                ruleForm.classes != '' &&
                ruleForm.type == 1) ||
              (ruleForm.classes == 10 &&
                ruleForm.classes != '' &&
                ruleForm.type == 4) ||
              (ruleForm.classes == 9 &&
                ruleForm.classes != '' &&
                ruleForm.type == 3) ||
              (ruleForm.classes == 6 &&
                ruleForm.classes != '' &&
                ruleForm.type == 2)
                ? ''
                : 'forTiem-R'
            "
          >
            <el-select
              v-model="ruleForm.classes"
              @change="classesChange"
              placeholder="请选择"
            >
              <el-option
                v-for="item in categoryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div></div>
        </div>
        <div class="flex">
          <el-form-item
            label="企业性质"
            prop="nature"
            :class="
              (ruleForm.classes != 5 &&
                ruleForm.classes != '' &&
                ruleForm.type == 1) ||
              (ruleForm.classes == 10 &&
                ruleForm.classes != '' &&
                ruleForm.type == 4) ||
              (ruleForm.classes == 9 &&
                ruleForm.classes != '' &&
                ruleForm.type == 3) ||
              (ruleForm.classes == 6 &&
                ruleForm.classes != '' &&
                ruleForm.type == 2)
                ? ''
                : 'forTiem-L'
            "
          >
            <el-select v-model="ruleForm.nature" placeholder="请选择">
              <el-option
                v-for="item in natureOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 用煤企业(除了其他行业) -->
          <el-form-item
            label="电厂名称(全称)"
            prop="name"
            v-if="
              ruleForm.classes != 5 &&
              ruleForm.classes != '' &&
              ruleForm.type == 1
            "
          >
            <el-input v-model="ruleForm.name" disabled></el-input>
          </el-form-item>
          <!-- 煤矿行业(生产煤矿) -->
          <el-form-item
            label="矿山名称(全称)"
            prop="name"
            v-if="
              ruleForm.classes == 6 &&
              ruleForm.classes != '' &&
              ruleForm.type == 2
            "
          >
            <el-input v-model="ruleForm.name" disabled></el-input>
          </el-form-item>

          <!-- 煤电一体化(除了其他行业) -->
          <el-form-item
            label="企业名称(全称)"
            prop="name"
            v-if="
              ruleForm.classes == 10 &&
              ruleForm.classes != '' &&
              ruleForm.type == 4
            "
          >
            <el-input v-model="ruleForm.name" disabled></el-input>
          </el-form-item>

          <!-- 贸易企业(地方政府认定的农村居民用煤经营单) -->
          <el-form-item
            label="所属省市区"
            prop="cityId"
            v-if="
              ruleForm.classes == 9 &&
              ruleForm.classes != '' &&
              ruleForm.type == 3
            "
          >
            <el-cascader
              @change="cityIdChange"
              v-model="ruleForm.cityId"
              :options="citys"
              placeholder="请选择所属省市区"
              :props="{
                value: 'id',
                label: 'name',
              }"
            ></el-cascader>
          </el-form-item>
        </div>
        <!-- 煤电一体化企业 -->
        <div
          class="flex"
          v-if="
            (ruleForm.classes == 10 &&
              ruleForm.classes != '' &&
              ruleForm.type == 4) ||
            (ruleForm.classes == 6 &&
              ruleForm.classes != '' &&
              ruleForm.type == 2)
          "
        >
          <el-form-item label="所属省市区" prop="cityId">
            <el-cascader
              @change="cityIdChange"
              v-model="ruleForm.cityId"
              :options="citys"
              placeholder="请选择所属省市区"
              :props="{
                value: 'id',
                label: 'name',
              }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="是否在产煤矿" prop="whetherProductCoal">
            <el-select
              v-model="ruleForm.whetherProductCoal"
              placeholder="请选择"
            >
              <el-option
                v-for="item in MKOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div
          class="flex"
          v-if="
            (ruleForm.classes == 10 &&
              ruleForm.classes != '' &&
              ruleForm.type == 4) ||
            (ruleForm.classes == 6 &&
              ruleForm.classes != '' &&
              ruleForm.type == 2)
          "
        >
          <el-form-item label="采矿权人" prop="miningName">
            <el-input v-model="ruleForm.miningName"></el-input>
          </el-form-item>
          <el-form-item label="采矿许可证" prop="miningLicenceUrl">
            <div style="display: flex">
              <el-input v-model="originalName" disabled></el-input>
              <el-upload
                class="upload-demo"
                action="#"
                :on-preview="handlePreview"
                multiple
                :on-exceed="handleExceed"
                :file-list="adjunctList"
                :http-request="handleChanges"
                :show-file-list="false"
                accept=".pdf,.PDF"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </div>
          </el-form-item>
        </div>
        <div
          class="flex"
          v-if="
            (ruleForm.classes == 10 &&
              ruleForm.classes != '' &&
              ruleForm.type == 4) ||
            (ruleForm.classes == 6 &&
              ruleForm.classes != '' &&
              ruleForm.type == 2)
          "
        >
          <el-form-item label="采矿许可证号" prop="miningLicenceNo">
            <el-input v-model="ruleForm.miningLicenceNo"></el-input>
          </el-form-item>
          <el-form-item label="证件有效期" prop="Certificate">
            <el-date-picker
              v-model="ruleForm.Certificate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="CertificateChange"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div
          class="flex"
          v-if="
            (ruleForm.classes == 10 &&
              ruleForm.classes != '' &&
              ruleForm.type == 4) ||
            (ruleForm.classes == 6 &&
              ruleForm.classes != '' &&
              ruleForm.type == 2)
          "
        >
          <el-form-item
            label="2021年9月之后新增核定产能(万吨)"
            prop="beforeYearCount"
          >
            <el-input v-model="ruleForm.beforeYearCount"></el-input>
          </el-form-item>
          <el-form-item
            label="2023年煤矿电煤签订任务量(万吨/年)"
            prop="taskLoad"
          >
            <el-input v-model="ruleForm.taskLoad"></el-input>
          </el-form-item>
        </div>

        <div
          class="flex"
          v-if="
            (ruleForm.classes == 10 &&
              ruleForm.classes != '' &&
              ruleForm.type == 4) ||
            (ruleForm.classes == 6 &&
              ruleForm.classes != '' &&
              ruleForm.type == 2)
          "
        >
          <el-form-item label="目前总核定产能(万吨/年)" prop="targetCount">
            <el-input v-model="ruleForm.targetCount"></el-input>
          </el-form-item>
          <el-form-item label="2022年预计产量(万吨)" prop="predictedOutput">
            <el-input v-model="ruleForm.predictedOutput"></el-input>
          </el-form-item>
        </div>

        <div
          class="flex"
          v-if="
            (ruleForm.classes == 6 &&
              ruleForm.classes != '' &&
              ruleForm.type == 2) ||
            (ruleForm.classes == 10 &&
              ruleForm.classes != '' &&
              ruleForm.type == 4)
          "
        >
          <el-form-item label="2023年计划产量(万吨)" prop="afterPlantOutput">
            <el-input v-model="ruleForm.afterPlantOutput"></el-input>
          </el-form-item>
          <el-form-item
            label="营业执照"
            prop="licenseUrl"
            v-if="
              ruleForm.classes == 10 &&
              ruleForm.classes != '' &&
              ruleForm.type == 4
            "
          >
            <!-- <el-input v-model="ruleForm.licenseUrl"></el-input> -->
            <el-button
              type="primary"
              size="small"
              @click="centerDialogVisible = true"
              >查看</el-button
            >
          </el-form-item>
        </div>

        <div
          class="flex"
          v-if="
            ruleForm.classes == 10 &&
            ruleForm.classes != '' &&
            ruleForm.type == 4
          "
        >
          <el-form-item label="是否统调电厂" prop="whetherTracking">
            <el-select v-model="ruleForm.whetherTracking" placeholder="请选择">
              <el-option
                v-for="item in TdOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="2022年用煤总量(万吨)" prop="currentYearCount">
            <el-input v-model="ruleForm.currentYearCount"></el-input>
          </el-form-item>
        </div>

        <div
          class="flex"
          v-if="
            ruleForm.classes == 10 &&
            ruleForm.classes != '' &&
            ruleForm.type == 4
          "
        >
          <el-form-item
            label="2023年国内煤炭预测用量(万吨)"
            prop="afterYearInternalCount"
          >
            <!-- <el-select v-model="ruleForm.afterYearInternalCount" placeholder="请选择">
              <el-option
                v-for="item in natureOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            <el-input v-model="ruleForm.afterYearInternalCount"></el-input>
          </el-form-item>

          <el-form-item
            label="2023年进口煤炭预测用量(万吨)"
            prop="afterYearImportCount"
            v-if="
              (ruleForm.classes != 5 &&
                ruleForm.classes != '' &&
                ruleForm.type == 1) ||
              (ruleForm.classes == 10 &&
                ruleForm.classes != '' &&
                ruleForm.type == 4)
            "
          >
            <el-input v-model="ruleForm.afterYearImportCount"></el-input>
          </el-form-item>
        </div>

        <!-- 用煤企业(除了其他行业) -->
        <div
          class="flex"
          v-if="
            ruleForm.classes != 5 &&
            ruleForm.classes != '' &&
            ruleForm.type == 1
          "
        >
          <el-form-item
            label="所属省市区"
            v-if="
              ruleForm.classes != 5 &&
              ruleForm.classes != '' &&
              ruleForm.type == 1
            "
            prop="cityId"
          >
            <el-cascader
              @change="cityIdChange"
              v-model="ruleForm.cityId"
              :options="citys"
              placeholder="请选择所属省市区"
              :props="{
                value: 'id',
                label: 'name',
              }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="营业执照" prop="licenseUrl">
            <!-- <el-input v-model="ruleForm.licenseUrl" disabled></el-input> -->
            <el-button
              type="primary"
              size="small"
              @click="centerDialogVisible = true"
              >查看</el-button
            >
          </el-form-item>
        </div>
        <!--煤矿企业-->
        <!-- <div
          class="flex"
          v-if="
            ruleForm.classes == 1 &&
            ruleForm.classes != '' &&
            ruleForm.type == 2
          "
        >
          <el-form-item label="所属省市区" prop="cityId">
            <el-cascader
              v-model="ruleForm.cityId"
              :options="citys"
              placeholder="请选择发货地址"
              :props="{
                value: 'value',
                label: 'label',
              }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="是否在产煤矿" prop="name">
            <el-select v-model="ruleForm.nature" placeholder="请选择">
              <el-option
                v-for="item in MKOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div> -->
        <!-- 用煤企业(除了其他行业) -->
        <div
          class="flex"
          v-if="
            ruleForm.classes != 5 &&
            ruleForm.classes != '' &&
            ruleForm.type == 1
          "
        >
          <el-form-item label="是否统调电厂" prop="whetherTracking">
            <el-select v-model="ruleForm.whetherTracking" placeholder="请选择">
              <el-option
                v-for="item in TdOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="2022年用煤总量(万吨)" prop="currentYearCount">
            <el-input v-model="ruleForm.currentYearCount"></el-input>
          </el-form-item>
        </div>
        <!-- 用煤企业(除了其他行业) -->
        <div
          class="flex"
          v-if="
            ruleForm.classes != 5 &&
            ruleForm.classes != '' &&
            ruleForm.type == 1
          "
        >
          <el-form-item
            label="2023年国内煤炭预测用量(万吨)"
            prop="afterYearInternalCount"
          >
            <el-input v-model="ruleForm.afterYearInternalCount"></el-input>
          </el-form-item>
          <el-form-item
            label="2023年进口煤炭预测用量(万吨)"
            prop="afterYearImportCount"
            v-if="
              ruleForm.classes != 5 &&
              ruleForm.classes != '' &&
              ruleForm.type == 1
            "
          >
            <el-input v-model="ruleForm.afterYearImportCount"></el-input>
          </el-form-item>
        </div>
        <!-- 煤矿行业(生产煤矿) -->
        <!-- <div
          class="flex"
          v-if="
            ruleForm.classes == 1 &&
            ruleForm.classes != '' &&
            ruleForm.type == 2
          "
        >
          <el-form-item label="采矿权人" prop="miningName">
            <el-input v-model="ruleForm.miningName"></el-input>
          </el-form-item>
          <el-form-item label="采矿许可证" prop="miningLicenceUrl">
            <el-input v-model="ruleForm.miningLicenceUrl"></el-input>
          </el-form-item>
        </div>
        <div
          class="flex"
          v-if="
            ruleForm.classes == 1 &&
            ruleForm.classes != '' &&
            ruleForm.type == 2
          "
        >
          <el-form-item label="采矿许可证号" prop="miningLicenceNo">
            <el-input v-model="ruleForm.miningLicenceNo"></el-input>
          </el-form-item>
          <el-form-item label="证件有效期" prop="name">
            <el-date-picker
              v-model="ruleForm.name1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div
          class="flex"
          v-if="
            ruleForm.classes == 1 &&
            ruleForm.classes != '' &&
            ruleForm.type == 2
          "
        >
          <el-form-item
            label="2021年9月之后新增核定产能()"
            prop="beforeYearCount"
          >
            <el-input v-model="ruleForm.beforeYearCount"></el-input>
          </el-form-item>
          <el-form-item label="2023年煤矿电煤签订任务量" prop="taskLoad">
            <el-input v-model="ruleForm.taskLoad"></el-input>
          </el-form-item>
        </div>
        <div
          class="flex"
          v-if="
            ruleForm.classes == 1 &&
            ruleForm.classes != '' &&
            ruleForm.type == 2
          "
        >
          <el-form-item label="目前总核定产能(万吨/年)" prop="targetCount">
            <el-input v-model="ruleForm.targetCount"></el-input>
          </el-form-item>
          <el-form-item label="2022年预计产量" prop="predictedOutput">
            <el-input v-model="ruleForm.predictedOutput"></el-input>
          </el-form-item>
        </div>
        <div
          class="flex"
          v-if="
            ruleForm.classes == 1 &&
            ruleForm.classes != '' &&
            ruleForm.type == 2
          "
        >
          <el-form-item label="2023年计划产量(万吨)" prop="name">
            <el-input v-model="ruleForm.name1"></el-input>
          </el-form-item>
        </div> -->

        <!-- 贸易企业 -->
        <div
          class="flex"
          v-if="
            ruleForm.classes == 9 &&
            ruleForm.classes != '' &&
            ruleForm.type == 3
          "
        >
          <el-form-item label="2022年用煤总量(万吨)" prop="currentYearCount">
            <el-input v-model="ruleForm.currentYearCount"></el-input>
          </el-form-item>
          <el-form-item
            label="2023年国内煤炭预测用量(万吨)"
            prop="afterYearInternalCount"
          >
            <el-input v-model="ruleForm.afterYearInternalCount"></el-input>
          </el-form-item>
        </div>
        <!-- 贸易  -->
        <div
          class="flex"
          v-if="
            ruleForm.classes == 9 &&
            ruleForm.classes != '' &&
            ruleForm.type == 3
          "
        >
          <el-form-item
            label="2023年进口煤炭预测用量(万吨)"
            prop="afterYearImportCount"
          >
            <el-input v-model="ruleForm.afterYearImportCount"></el-input>
          </el-form-item>
        </div>
        <div class="btns">
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交</el-button
            >
            <el-button @click="resetForm('ruleForm')">关闭</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div class="diglogImg">
        <!-- <img :src="licenseUrl" alt="" /> -->
        <el-image
          style="width: 200px"
          :src="licenseUrl"
          :preview-src-list="srcList"
        >
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addAndEditEnterpriseAuth,
  ossUploadFile2,
  getTreeV2,
} from "@/api/Medium.js";
import { getCompanyAuthDetail } from "@/api/userCenters.js";
import { getEnterpriseAuthInfo } from "@/api/Medium.js";
import Vue from "vue";
const { baseUrl } = require("@/config/index.js");
export default {
  data() {
    return {
      srcList: [],
      licenseUrl: "",
      originalName: "",
      adjunctList: [],
      centerDialogVisible: false,
      citys: [],
      options: [
        {
          value: "1",
          label: "用煤企业",
        },
        {
          value: "2",
          label: "煤矿企业",
        },
        {
          value: "3",
          label: "贸易企业",
        },
        {
          value: "4",
          label: "煤电一体化企业",
        },
      ],
      categoryOptions: [],
      MKOptions: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      TdOptions: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      natureOptions: [
        {
          value: "1",
          label: "中央企业",
        },
        {
          value: "2",
          label: "地方国有",
        },
        {
          value: "3",
          label: "民营企业",
        },
      ],
      ruleForm: {
        afterPlantOutput: "",
        afterYearImportCount: "",
        afterYearInternalCount: "",
        beforeYearCount: "",
        cityId: "",
        classes: "",
        currentYearCount: "",
        id: "",
        licenseUrl: "",
        miningLicenceNo: "",
        miningLicenceUrl: "",
        miningLicenceValidityEnd: "",
        miningLicenceValidityStart: "",
        miningName: "",
        name: "",
        nature: "",
        predictedOutput: "",
        targetCount: "",
        taskLoad: "",
        type: "",
        whetherProductCoal: "",
        whetherTracking: "",
        Certificate: [],
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        type: [
          { required: true, message: "请选择企业类型", trigger: "change" },
        ],
        classes: [
          { required: true, message: "请选择企业类别", trigger: "change" },
        ],
        nature: [
          { required: true, message: "请选择企业性质", trigger: "change" },
        ],
        cityId: [
          { required: true, message: "请选择省市区", trigger: "change" },
        ],
        whetherProductCoal: [
          { required: true, message: "请选择是否在产煤矿", trigger: "change" },
        ],
        miningName: [
          { required: true, message: "请选择采矿权人", trigger: "change" },
        ],
        miningLicenceUrl: [
          { required: true, message: "请上传采矿许可证", trigger: "change" },
        ],
        miningLicenceNo: [
          { required: true, message: "请输入采矿许可证号", trigger: "blur" },
        ],
        miningLicenceValidityEnd: [
          { required: true, message: "请选择证件开始日期", trigger: "change" },
        ],
        miningLicenceValidityStart: [
          { required: true, message: "请选择证件结束日期", trigger: "change" },
        ],
        Certificate: [
          { required: true, message: "请选择有效期", trigger: "change" },
        ],
        beforeYearCount: [
          {
            required: true,
            message: "请输入2021年9月之后新增核定产能",
            trigger: "blur",
          },
        ],
        taskLoad: [
          {
            required: true,
            message: "请输入2023年煤矿电焊签订任务量",
            trigger: "blur",
          },
        ],
        targetCount: [
          { required: true, message: "请输入目前总核定产能", trigger: "blur" },
        ],
        predictedOutput: [
          { required: true, message: "请输入2022年预计产量", trigger: "blur" },
        ],
        afterPlantOutput: [
          { required: true, message: "请输入2023年计划产量", trigger: "blur" },
        ],
        licenseUrl: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
        whetherTracking: [
          { required: true, message: "请选择是否统调电厂", trigger: "change" },
        ],
        currentYearCount: [
          { required: true, message: "请输入2022年用煤总量", trigger: "blur" },
        ],
        afterYearImportCount: [
          {
            required: true,
            message: "请输入2023年进口煤炭预测用量",
            trigger: "blur",
          },
        ],
        afterYearInternalCount: [
          {
            required: true,
            message: "请选择2023年国内煤炭预测用量",
            trigger: "change",
          },
        ],
      },
      typen: null,
    };
  },
  props: {
    listall: {
      type: Object,
    },
  },
  mounted() {
    this.$refs['ruleForm'].resetFields();
    this.getdetil();
    this.ruleForm.name = window.localStorage.getItem("userName");
    console.log(this.ruleForm.name);
  },
  methods: {
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件`);
    },
    handleChanges(file) {
      this.ruleForm.miningLicenceUrl = "";
      let files = file.file;
      let imageType = /\.(pdf|PDF)$/;
      const isJPG = imageType.test(files.name);
      if (!isJPG) {
        this.$message.error("上传文件只能是 pdf/PDF 格式!");
      }
      if (isJPG) {
        // this.otherFiles = file.raw;
        // FormData 对象
        var formData = new FormData();
        // 文件对象
        formData.append("file", files);
        ossUploadFile2(formData).then((res) => {
          if (res.code == 0) {
            this.originalName = res.data.originalName;
            this.ruleForm.miningLicenceUrl = res.data.storageName;
            // this.fileIds.push(res.data.fileId);
            // this.contimgs.push(res.data);
            // files.fileId = res.data.fileId;
            // this.adjunctList.push(files);
            console.log(this.ruleForm.miningLicenceUrl);
            this.$message.success({
              title: "提示",
              message: "文件上传成功",
            });
          }
        });
      } else {
        // this.fileList = [];
      }
    },
    getdata() {
      getCompanyAuthDetail().then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.ruleForm.name = res.data.companyName;
          // baseUrl +"third/third/ossGetFile/" +businessLicenseUrl
          this.licenseUrl =
            baseUrl + "third/third/ossGetFile/" + res.data.businessLicenseUrl;
          this.ruleForm.licenseUrl = res.data.businessLicenseUrl;
          this.srcList.push(
            baseUrl + "third/third/ossGetFile/" + res.data.businessLicenseUrl
          );
          //  this.$set(this.ruleForm.Certificate, 0, res.data.miningLicenceValidityStart);
          // this.$set(this.ruleForm.Certificate, 1,res.data.miningLicenceValidityEnd);
          console.log(this.ruleForm.licenseUrl);
        }
      });
    },

    getdetil() {
      this.getdata();
      getTreeV2().then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.citys = this.deleteChildren(res.data);
          // this.citys = res.data;
        }
      });
      // if (this.listall.whetherProductCoal) {
      //   this.listall.whetherProductCoal = "是";
      // } else if (!this.listall.whetherProductCoal) {
      //   this.listall.whetherProductCoal = "否";
      // }
      if (
        this.listall.miningLicenceValidityStart &&
        this.listall.miningLicenceValidityEnd
      ) {
        let list = [
          this.listall.miningLicenceValidityStart,
          this.listall.miningLicenceValidityEnd,
        ];
        this.ruleForm.Certificate = list;
      }
      console.log(this.ruleForm.Certificate);

      this.ruleForm = this.listall;
      // this.regionChange(this.listall.type);
      let e = this.listall.type;
      if (e == 1) {
        this.categoryOptions = [
          {
            value: "1",
            label: "电力发电用煤",
          },
          {
            value: "2",
            label: "热电联产用煤",
          },
          {
            value: "3",
            label: "独立供暖用煤",
          },
          {
            value: "4",
            label: "自备电厂用煤",
          },
          {
            value: "5",
            label: "其他行业用煤",
          },
        ];
      } else if (e == 2) {
        this.categoryOptions = [
          {
            value: "6",
            label: "生产煤矿",
          },
        ];
      } else if (e == 3) {
        this.categoryOptions = [
          {
            value: "7",
            label: "煤炭企业销售公司",
          },
          {
            value: "11",
            label: "电厂企业采购公司",
          },
          {
            value: "8",
            label: "其他贸易企业",
          },
          {
            value: "9",
            label: "地方政府认定的农村居民用煤经营单位",
          },
        ];
      } else if (e == 4) {
        this.categoryOptions = [
          {
            value: "10",
            label: "煤电一体化企业",
          },
        ];
      }
    },
    deleteChildren(arr) {
      let childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].children) {
          if (childs[i].children.length) {
            this.deleteChildren(childs[i].children);
          } else {
            delete childs[i].children;
          }
        }
      }
      return arr;
    },
    classesChange(e) {
      if (e) {
        this.ruleForm.afterPlantOutput= "",
        this.ruleForm.afterYearImportCount= "",
        this.ruleForm.afterYearInternalCount= "",
        this.ruleForm.beforeYearCount= "",
        this.ruleForm.cityId= "",
        this.ruleForm.currentYearCount= "",
        this.ruleForm.id= "",
        this.ruleForm.miningLicenceNo= "",
        this.ruleForm.miningLicenceValidityEnd= "",
        this.ruleForm.miningLicenceValidityStart= "",
        this.ruleForm.miningName= "",
        this.ruleForm.cityName= "",
        this.ruleForm.predictedOutput= "",
        this.ruleForm.targetCount= "",
        this.ruleForm.taskLoad= "",
        this.ruleForm.whetherProductCoal= "",
        this.ruleForm.whetherTracking= "",
        this.ruleForm.Certificate= []
        // this.$refs["ruleForm"].resetFields();
      }
    },

    regionChange(e) {
      if (e == 1) {
        this.categoryOptions = [
          {
            value: "1",
            label: "电力发电用煤",
          },
          {
            value: "2",
            label: "热电联产用煤",
          },
          {
            value: "3",
            label: "独立供暖用煤",
          },
          {
            value: "4",
            label: "自备电厂用煤",
          },
          {
            value: "5",
            label: "其他行业用煤",
          },
        ];
      } else if (e == 2) {
        this.categoryOptions = [
          {
            value: "6",
            label: "生产煤矿",
          },
        ];
      } else if (e == 3) {
        this.categoryOptions = [
          {
            value: "7",
            label: "煤炭企业销售公司",
          },
          {
            value: "11",
            label: "电厂企业采购公司",
          },
          {
            value: "8",
            label: "其他贸易企业",
          },
          {
            value: "9",
            label: "地方政府认定的农村居民用煤经营单位",
          },
        ];
      } else if (e == 4) {
        this.categoryOptions = [
          {
            value: "10",
            label: "煤电一体化企业",
          },
        ];
      }
      this.ruleForm.classes = "";
      this.originalName = "";
      this.ruleForm = {
        afterPlantOutput: "",
        afterYearImportCount: "",
        afterYearInternalCount: "",
        beforeYearCount: "",
        cityId: "",
        classes: "",
        currentYearCount: "",
        miningLicenceNo: "",
        miningLicenceUrl: "",
        miningLicenceValidityEnd: "",
        miningLicenceValidityStart: "",
        miningName: "",
        nature: "",
        predictedOutput: "",
        targetCount: "",
        taskLoad: "",
        whetherProductCoal: "",
        whetherTracking: "",
        type: e,
        Certificate: "",
      };
      this.getdata();
    },
    cityIdChange(e) {
      this.ruleForm.cityId = e[2];
    },
    CertificateChange(e) {
      console.log(e);
      this.ruleForm.miningLicenceValidityEnd = e[0];
      // var date = new Date(e[0]);
      // var time1 = date.getTime();
      // this.ruleForm.miningLicenceValidityEnd = time1;
      this.ruleForm.miningLicenceValidityStart = e[1];
      // var dates = new Date(e[1]);
      // var time2 = dates.getTime();
      // this.ruleForm.miningLicenceValidityStart = time2;
    },
    dateFormat(fmt, date) {
      let o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
    submitForm(ruleForm) {
      this.ruleForm.createTime = this.dateFormat(
        "yyyy-MM-dd hh:mm:ss",
        new Date()
      );
      console.log(this.ruleForm);

      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认提交?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              addAndEditEnterpriseAuth(this.ruleForm).then((res) => {
                console.log(res);
                if (res.code == 0) {
                  this.$emit("transfer", res);
                  this.$message({
                    type: "success",
                    message: "提交成功,请等待平台审核.",
                  });
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    resetForm() {
      this.$emit("GBsfer", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.contentALL {
  width: 100%;
  margin: 40px auto 0;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.btns {
  text-align: center;
  margin-top: 90px;
}
::v-deep .btns {
  .el-form-item {
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
}
.diglogImg {
  text-align: center;
  img {
    width: auto;
    height: auto;
  }
}
.upload-demo {
  margin-left: 10px;
}
::v-deep .el-date-editor {
  .el-range-separator {
    padding: 0;
  }
}
.forTiem-L {
  margin-left: 100px;
}
.forTiem-R {
  margin-right: 100px;
}
</style>